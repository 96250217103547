import React from "react";
import { imagesReplace } from "../shared/utils/mixedControl";
import styled from "styled-components";

// This component is designed to add background image to the parent component.
// Parent component must have position: relative; property.
// Parent component must have width and height properties.
// Parent component must have z-index property.
/* 
    <BackgroundImage
          url={imagePath}
          backgroundEffect={"rgba(0, 31, 104, 0.80)"}
          opacity={"1"}
    />
*/

const BackgroundImageStyled = styled.div`
  width: 100%;
  height: 100%;
  z-index: -99;
  position: absolute;
  top: 0;
  left: 0;

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) => props?.backgroundEffect || "rgba(0, 31, 104)"};
    opacity: ${(props) => props?.opacity || "0.8"};
    pointer-events: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const BackgroundImage = (props) => {
  return (
    <BackgroundImageStyled
      backgroundEffect={props.backgroundEffect}
      opacity={props.opacity}
    >
      <img src={imagesReplace(props?.url)} alt={props?.alt} width={"100%"} height={"100%"} />
    </BackgroundImageStyled>
  );
};

export default BackgroundImage;
